<template>
  <div class="dashboard-container">
    <h2 class="dashboard-title">{{ msg }}</h2>
    
    <div class="metrics-grid">
      <div class="metric-card" :class="{ 'loading': loading }">
        <h3>Market Cap</h3>
        <p class="metric-value">{{ metrics.marketCap }}</p>
        <p :class="['metric-change', changes.marketCap.type]">
          {{ changes.marketCap.value }}%
        </p>
      </div>

      <div class="metric-card" :class="{ 'loading': loading }">
        <h3>24h Volume</h3>
        <p class="metric-value">{{ metrics.volume }}</p>
        <p :class="['metric-change', changes.volume.type]">
          {{ changes.volume.value }}%
        </p>
      </div>

      <div class="metric-card" :class="{ 'loading': loading }">
        <h3>SOL Price</h3>
        <p class="metric-value">${{ metrics.currentPrice }}</p>
        <p :class="['metric-change', changes.priceChange.type]">
          {{ changes.priceChange.value }}%
        </p>
      </div>

      <div class="metric-card" :class="{ 'loading': loading }">
        <h3>Liquidity</h3>
        <p class="metric-value">{{ metrics.liquidity }}</p>
        <p :class="['metric-change', changes.liquidity.type]">
          {{ changes.liquidity.value }}%
        </p>
      </div>
    </div>

    <div class="search-section">
      <h3 class="search-title">Explore Solana Token Universe</h3>
      <p class="search-subtitle">Discover detailed analytics and real-time metrics for any Solana token</p>
      <form class="token-form" @submit.prevent>
        <div class="input-wrapper">
          <input 
            type="text" 
            placeholder="Input Token CA" 
            class="token-input"
            v-model="tokenCA"
          >
          <button class="discover-btn" @click="discoverToken">
            Discover
            <span class="btn-glow"></span>
          </button>
        </div>
      </form>
    </div>

    <div class="trending-section">
      <h3>24H Trending Tokens on DexScreener</h3>
      <div class="token-table">
        <table>
          <thead>
            <tr>
              <th>Token</th>
              <th>Price (USD)</th>
              <th>Market Cap</th>
              <th>Boost</th>
              <th>Links</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="token in trendingTokens" :key="token.name">
              <td class="token-cell">
                <a :href="token.url" target="_blank" class="token-link">
                  <img 
                    :src="token.icon"
                    :alt="token.name"
                    class="token-img"
                    @error="handleTokenImageError"
                  >
                  <span class="token-name">{{ token.name }}</span>
                </a>
              </td>
              <td class="price-cell">${{ token.priceUsd || '0.00' }}</td>
              <td class="mcap-cell">{{ token.marketCap }}</td>
              <td class="boost-cell">
                <span class="boost-value">{{ token.boost }}</span>
              </td>
              <td class="token-links">
                <button 
                  class="copy-btn" 
                  @click="copyAddress(token.address)"
                  title="Copy Address"
                >
                  <img src="@/assets/copy.png" alt="Copy" class="link-icon">
                  <i class="fas fa-copy"></i>
                </button>
                <a v-for="(link, index) in token.links" 
                   :key="index" 
                   :href="link.url || link"
                   target="_blank"
                   class="social-link"
                   :title="getLinkType(link)">
                  <img v-if="isXLink(link)" 
                       src="@/assets/twitter.png" 
                       alt="X Logo"
                       class="social-icon"
                  />
                  <img v-else-if="isTelegramLink(link)"
                       src="@/assets/telegram.png"
                       alt="Telegram Logo"
                       class="social-icon"
                  />
                  <img v-else-if="isDiscordLink(link)"
                       src="@/assets/discord.png"
                       alt="Discord Logo"
                       class="social-icon"
                  />
                  <span v-else>🔗</span>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="toast" v-show="showCopyToast">
      Copied CA to Clipboard
    </div>
  </div>
  <div class="trending-section">
    <h3>Helping You Protect Your Bag</h3>
    <h4> Follow MemeMetrics on X! @MemeMetricsApp </h4>
    <div v-if="trendingLoading" class="loading-indicator">Loading...</div>
    <div v-else-if="trendingTokens.length === 0" class="no-data">No tokens found</div>
    <div v-else class="token-table">
      <!-- Rest of table template -->
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import axios from 'axios'

export default {
  name: 'MainDash',
  props: {
    msg: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      tokenCA: '',
      loading: true,
      metrics: {
        marketCap: '0',
        volume: '0',
        currentPrice: '0',
        liquidity: '0'
      },
      changes: {
        marketCap: { value: 0, type: 'neutral' },
        volume: { value: 0, type: 'neutral' },
        priceChange: { value: 0, type: 'neutral' },
        liquidity: { value: 0, type: 'neutral' }
      },
      trendingTokens: [],
      trendingLoading: false,
      intervals: {
        solana: null,
        trending: null
      },
      cache: {
        lastUpdate: Date.now(),
        data: null
      },
      CACHE_DURATION: 300000, // 5 minutes in milliseconds
      topHolders: [],
      holdersLoading: false,  // Added comma here
      showCopyToast: false
    }
  },

  computed: {
    filteredTokens() {
      return this.trendingTokens.filter(token => 
        token.marketCap && parseFloat(token.marketCap) > 0
      )
    }
  },

  methods: {
    fetchSolanaData: debounce(async function() {
      try {
        this.loading = true
        const [current, historical] = await Promise.all([
          axios.get('https://api.coingecko.com/api/v3/simple/price', {
            params: {
              ids: 'solana',
              vs_currencies: 'usd',
              include_24hr_vol: true,
              include_market_cap: true,
              include_24hr_change: true
            }
          }),
          axios.get('https://api.coingecko.com/api/v3/coins/solana/market_chart', {
            params: {
              vs_currency: 'usd',
              days: 1,
              interval: 'daily'
            }
          })
        ])
        const data = current.data.solana
        const hist = historical.data
        
        // Calculate percent changes
        const marketCapChange = ((data.usd_market_cap - hist.market_caps[0][1]) / hist.market_caps[0][1]) * 100
        const volumeChange = ((data.usd_24h_vol - hist.total_volumes[0][1]) / hist.total_volumes[0][1]) * 100
        const liquidityChange = marketCapChange * 0.1 // Simplified liquidity calculation
        
        this.metrics = {
          marketCap: this.formatCurrency(data.usd_market_cap),
          volume: this.formatCurrency(data.usd_24h_vol),
          currentPrice: data.usd.toFixed(2),
          priceChange: data.usd_24h_change.toFixed(2) + '%',
          liquidity: this.formatCurrency(data.usd_market_cap * 0.1)
        }

        this.changes = {
          marketCap: { 
            value: marketCapChange.toFixed(2),
            type: marketCapChange > 0 ? 'positive' : marketCapChange < 0 ? 'negative' : 'neutral'
          },
          volume: { 
            value: volumeChange.toFixed(2),
            type: volumeChange > 0 ? 'positive' : volumeChange < 0 ? 'negative' : 'neutral'
          },
          priceChange: { 
            value: data.usd_24h_change.toFixed(2),
            type: data.usd_24h_change > 0 ? 'positive' : data.usd_24h_change < 0 ? 'negative' : 'neutral'
          },
          liquidity: { 
            value: liquidityChange.toFixed(2),
            type: liquidityChange > 0 ? 'positive' : liquidityChange < 0 ? 'negative' : 'neutral'
          }
        }
      } catch (error) {
        console.error('Error fetching Solana data:', error)
        if (error.response?.status === 429) {
          console.error('API rate limit exceeded')
        }
      } finally {
        this.loading = false
      }
    }, 300),

    async fetchTrendingTokens() {
      try {
        this.trendingLoading = true
        console.log('Fetching trending tokens...')
        
        const response = await axios.get('https://api.dexscreener.com/token-boosts/top/v1')
        console.log('API Response:', response.data)
  
        if (!response.data) {
          throw new Error('No data found')
        }
  
        const tokenDetails = await Promise.all(
          response.data.map(async (token) => {
            console.log('Processing token:', token)
            const tokenAddress = token.url.split('/').pop()
            const details = await this.fetchTokenDetails(tokenAddress)
            
            return {
              name: details?.name,
              address: details?.address,
              url: token.url,
              links: token.links || [],
              icon: token.icon,
              boost: token.totalAmount || '0',
              priceUsd: details?.priceUsd || '0.00',
              marketCap: details?.marketCap || '0'
            }
          })
        )
  
        console.log('Processed tokens:', tokenDetails)
        this.trendingTokens = tokenDetails
  
      } catch (error) {
        console.error('Error fetching trending tokens:', error)
        this.trendingTokens = []
      } finally {
        this.trendingLoading = false
      }
    },
  
    getLinkType(link) {
      const url = link.url || link
      if (url.includes('x.com')) return 'X'
      if (url.includes('t.me')) return 'Telegram'
      if (url.includes('discord')) return 'Discord'
      return 'Website'
    },

    isXLink(link) {
      const url = link.url || link
      return url.includes('x.com')
    },

    isTelegramLink(link) {
      const url = link.url || link
      return url.includes('t.me')
    },

    isDiscordLink(link) {
      const url = link.url || link
      return url.includes('discord')
    },
   
    formatCurrency(value) {
      if (value >= 1e9) return `$${(value / 1e9).toFixed(2)}B`
      if (value >= 1e6) return `$${(value / 1e6).toFixed(2)}M`
      if (value >= 1e3) return `$${(value / 1e3).toFixed(2)}K`
      return `$${value.toFixed(2)}`
    },
    formatMarketCap(value) {
      if (!value) return '$0'
      const num = parseFloat(value)
      if (num >= 1e9) return `$${(num / 1e9).toFixed(1)}B`
      if (num >= 1e6) return `$${(num / 1e6).toFixed(1)}M`
      if (num >= 1e3) return `$${(num / 1e3).toFixed(1)}K`
      return `$${num.toFixed(0)}`
    },
    updateChanges(data) {
      // Example change calculations
      const changes = {
        marketCap: ((data.usd_market_cap - data.usd_market_cap_24h_old) / data.usd_market_cap_24h_old) * 100,
        volume: ((data.usd_24h_vol - data.usd_24h_vol_old) / data.usd_24h_vol_old) * 100
      }
      
      Object.keys(changes).forEach(key => {
        this.changes[key] = {
          value: changes[key].toFixed(1),
          type: changes[key] > 0 ? 'positive' : changes[key] < 0 ? 'negative' : 'neutral'
        }
      })
    },
    discoverToken() {
      if (this.tokenCA) {
        this.$router.push({
          name: 'discover',
          params: { tokenCA: this.tokenCA }
        })
      }
    },
    handleTokenImageError(e) {
      e.target.src = require('@/assets/solana.png')
    },
    async fetchTokenDetails(tokenAddress) {
      try {
        console.log('Fetching details for:', tokenAddress)
        const response = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`)
        
        if (response.data?.pairs?.[0]) {
          return {
            name: response.data.pairs[0].baseToken.name,
            priceUsd: response.data.pairs[0].priceUsd,
            marketCap: response.data.pairs[0].marketCap,
            address: response.data.pairs[0].baseToken.address
          }
        }
        return null
      } catch (error) {
        console.error(`Error fetching token details for ${tokenAddress}:`, error)
        return null
      }
    },

    async copyAddress(address) {
    if (!address) return;
    
    try {
      await navigator.clipboard.writeText(address);
      const target = event.currentTarget;
      target.classList.add('success');
      const icon = target.querySelector('i');
      icon.classList.remove('fa-copy');
      icon.classList.add('fa-check');
      
      setTimeout(() => {
        target.classList.remove('success');
        icon.classList.remove('fa-check');
        icon.classList.add('fa-copy');
      }, 1500);
      
    } catch (err) {
      // Fallback with same visual feedback
      const textarea = document.createElement('textarea');
      textarea.value = address;
      textarea.style.position = 'fixed';
      textarea.style.opacity = '0';
      document.body.appendChild(textarea);
      textarea.select();
      
      try {
        document.execCommand('copy');
        const target = event.currentTarget;
        target.classList.add('success');
        const icon = target.querySelector('i');
        icon.classList.remove('fa-copy');
        icon.classList.add('fa-check');
        
        setTimeout(() => {
          target.classList.remove('success');
          icon.classList.remove('fa-check');
          icon.classList.add('fa-copy');
        }, 1500);
      } catch (e) {
        console.error('Copy failed:', e);
      }
      document.body.removeChild(textarea);
    }
  },

    async copyTokenCA(address) {  // Update existing copy method or add new one
      try {
        await navigator.clipboard.writeText(address);
        const target = event.target;
        target.innerHTML = '<i class="fas fa-check"></i>';
        setTimeout(() => {
          target.innerHTML = '<i class="fas fa-copy"></i>';
        }, 2000);
      } catch (err) {
        // Fallback for mobile
        const textarea = document.createElement('textarea');
        textarea.value = address;
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';
        document.body.appendChild(textarea);
        textarea.select();
        try {
          document.execCommand('copy');
          const target = event.target;
          target.innerHTML = '<i class="fas fa-check"></i>';
          setTimeout(() => {
            target.innerHTML = '<i class="fas fa-copy"></i>';
          }, 2000);
        } catch (e) {
          console.error('Copy failed:', e);
        }
        document.body.removeChild(textarea);
      }
    },

  },
  created() {
    this.fetchSolanaData()
    this.fetchTrendingTokens()
    // Store interval references
    this.intervals.solana = setInterval(this.fetchSolanaData, 30000)
    this.intervals.trending = setInterval(this.fetchTrendingTokens, 300000)
  },
  beforeUnmount() {
    // Only clear intervals
    Object.values(this.intervals).forEach(interval => {
      if (interval) clearInterval(interval)
    })
  }
}
</script>

<style scoped>
.dashboard-container {
  padding: 2rem;
}

.dashboard-title {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  background: linear-gradient(90deg, var(--text-glow), var(--accent));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 20px rgba(228, 181, 255, 0.3);
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  margin: -4rem auto 0; /* Added negative top margin */
  max-width: 1200px;
  padding: 0 1rem;
  box-sizing: border-box;
}

.metric-card {
  background: rgba(26, 11, 46, 0.4);
  border: 1px solid rgba(230, 181, 255, 0.1);
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 576px) {
  .metrics-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 0.75rem;
    margin: -3rem auto 0; /* Added negative top margin for mobile */
    width: calc(100% - 1rem);
    max-width: none;
    justify-content: center;
    padding: 0;
  }

  .metric-card {
    height: 130px;
    padding: 0.75rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  .metric-card h3 {
    font-size: 0.85rem;
    margin-bottom: 0.25rem;
    white-space: nowrap;
  }

  .metric-value {
    font-size: 0.9rem;
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }

  .metric-change {
    font-size: 0.8rem;
    white-space: nowrap;
  }
}

.metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(182, 36, 255, 0.2);
}

.metric-card h3 {
  color: var(--text-glow);
  margin: 0 0 1rem 0;
  font-size: 1.1rem;
  opacity: 0.8;
}

.metric-value {
  font-size: 2rem;
  font-weight: bold;
  margin: 0.5rem 0;
  color: white;
}

.metric-change {
  font-size: 1.1rem;
  margin: 0;
}

.positive { color: #00ff9d; }
.negative { color: #ff4b4b; }
.neutral { color: #ffaa00; }

.search-section {
  margin-bottom: 2rem;
}

.token-form {
  background: rgba(26, 11, 46, 0.4);
  border-radius: 8px;
  padding: 1.5rem;
  border: 1px solid rgba(230, 181, 255, 0.1);
  max-width: 800px;
  width: calc(100% - 2rem); /* Account for padding */
  margin: 0 auto; /* Center the form */
  box-sizing: border-box;
}

.input-wrapper {
  display: flex;
  gap: 1rem;
  width: 100%;
}

@media (max-width: 576px) {
  .input-wrapper {
    flex-direction: column;
    gap: 1rem;
  }

  .token-input {
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
  }

  .token-form {
    padding: 1rem;
    margin: 1rem auto; /* Center horizontally */
    width: calc(100% - 2rem);
  }
}

.token-input {
  flex: 1;
  background: rgba(13, 27, 60, 0.5);
  border: 1px solid rgba(230, 181, 255, 0.1);
  box-sizing: border-box;
  width: 100%;
  color: white; /* Add text color */
}

.token-input:focus {
  outline: none;
  border-color: var(--accent);
  box-shadow: 0 0 15px rgba(0, 255, 242, 0.2);
}

.token-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.discover-btn {
  background: linear-gradient(90deg, var(--primary-purple), var(--primary-blue));
  border: none;
  border-radius: 6px;
  padding: 0.8rem 1.5rem;
  color: white;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.discover-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 0 20px rgba(182, 36, 255, 0.3);
}

.btn-glow {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: radial-gradient(circle, rgba(182, 36, 255, 0.2) 0%, transparent 70%);
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.trending-section {
  margin: 2rem auto;
  max-width: 1200px;
  padding: 0 1rem;
}

.token-table {
  width: 100%;
  background: rgba(26, 11, 46, 0.4);
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(230, 181, 255, 0.1);
}

@media (max-width: 576px) {
  .token-table table {
    display: block;
    width: 100%;
  }

  .token-table thead {
    display: none;
  }

  .token-table tbody {
    display: block;
    width: 100%;
  }

  .token-table tr {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    border-bottom: 1px solid rgba(230, 181, 255, 0.1);
  }

  .token-table td {
    display: flex;
    padding: 0.25rem;
    border: none;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1.2;
    gap: 0.5rem;
  }

  .token-cell::before,
  .price-cell::before,
  .mcap-cell::before,
  .boost-cell::before {
    min-width: 60px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.75rem;
  }

  .token-cell::before { content: "Token: "; }
  .price-cell::before { content: "Price: "; }
  .mcap-cell::before { content: "MCap: "; }
  .boost-cell::before { content: "Boost: "; }

  .token-cell .token-link {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  .token-img {
    width: 18px;
    height: 18px;
  }

  .token-name {
    font-size: 0.8rem;
  }

  .token-table td:not(.links-cell) {
    display: flex;
    padding: 0.25rem;
    border: none;
    align-items: center;
    font-size: 0.8rem;
    line-height: 1.2;
    gap: 0.5rem;
  }
}
.token-table {
  width: 100%;
  background: rgba(26, 11, 46, 0.4);
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(230, 181, 255, 0.1);
  contain: content;
}

table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

th, td {
  padding: 1.2rem;
  text-align: left;
  border-bottom: 1px solid rgba(230, 181, 255, 0.1);
  vertical-align: middle; /* Align row content vertically */
}

th {
  background: linear-gradient(
    180deg, 
    rgba(13, 27, 60, 0.8) 0%,
    rgba(13, 27, 60, 0.5) 100%
  );
  color: var(--accent);
  font-size: 1.1rem;
  font-weight: 600;
  white-space: nowrap;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-shadow: 0 0 10px rgba(0, 255, 242, 0.3);
  transition: background-color 0.3s;
}

/* Column widths */
th:nth-child(1), td:nth-child(1) { width: 30%; } /* Token */
th:nth-child(2), td:nth-child(2) { width: 15%; } /* Price */
th:nth-child(3), td:nth-child(3) { width: 20%; } /* Market Cap */
th:nth-child(4), td:nth-child(4) { width: 15%; } /* Boost */
th:nth-child(5), td:nth-child(5) { width: 20%; } /* Links */

.token-cell {
  padding: 0;
  height: 100%;
}

.token-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.2rem;
  color: var(--text-glow);
  text-decoration: none;
  transition: all 0.2s;
  height: 100%;
}

.price-cell, .mcap-cell {
  text-align: right;
  color: var(--text-glow);
  padding-right: 2rem;
  font-family: 'Roboto Mono', monospace;
}

.boost-cell {
  text-align: right;
  padding-right: 2rem;
}

tr {
  transition: background-color 0.2s;
}

tr:hover {
  background: rgba(182, 36, 255, 0.1);
}

tr:hover .token-link {
  color: var(--accent);
}

/* Add subtle line between columns */
td:not(:last-child), th:not(:last-child) {
  border-right: 1px solid rgba(230, 181, 255, 0.05);
}

.token-cell {
  padding: 0;
}

.token-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  color: var(--text-glow);
  text-decoration: none;
  transition: background-color 0.2s;
}

.token-link:hover {
  background: rgba(182, 36, 255, 0.1);
  color: var(--accent);
}

.token-img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  will-change: transform;
  contain: layout;
}

.token-name-wrapper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.link-btn {
  background: rgba(20, 241, 149, 0.1);
  border: none;
  padding: 0.35rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
}

.link-btn:hover {
  background: rgba(20, 241, 149, 0.2);
  transform: translateY(-2px);
}

.link-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
}

.token-name {
  font-weight: 500;
}

.x-logo {
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.social-icon {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  transition: transform 0.2s;
}

.social-link:hover .social-icon {
  transform: scale(1.2);
}

.boost-cell {
  text-align: right;
}

.boost-value {
  color: #FFD700; /* Yellow color */
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
}

.price-cell, .mcap-cell {
  text-align: right;
  color: var(--text-glow);
}

.search-title {
  text-align: center;
  margin-bottom: 0.5rem;
  font-size: 1.8rem;
  font-weight: 600;
  background: linear-gradient(45deg, #14F195, #9945FF);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.search-subtitle {
  text-align: center;
  margin-bottom: 1.5rem;
  color: #718096;
  font-size: 1rem;
}

.token-links {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.copy-btn {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  min-height: 44px;
  min-width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.3s ease;
}

.copy-btn:hover {
  background: rgba(20, 241, 149, 0.1);
}

.copy-btn:active,
.copy-btn.success {
  background: rgba(20, 241, 149, 0.4); /* Increased opacity */
}

.copy-btn i {
  transition: all 0.3s ease;
  color: rgba(255, 255, 255, 0.7);
}

.copy-btn.success i {
  color: #14F195;
  transform: scale(1.1); /* Add slight scale effect */
}

@media (max-width: 576px) {
  .copy-btn {
    padding: 12px;
    background: rgba(255, 255, 255, 0.05);
  }
}

.toast {
  position: fixed;
  transform: translateX(-50%);
  background: rgba(20, 241, 149, 0.2);
  border: 1px solid #14F195;
  color: #14F195;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  z-index: 99999;
  pointer-events: none;
  animation: fadeInOut 0.3s ease;
  white-space: nowrap;
}

.toast.hide {
  opacity: 0;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: translate(-50%, 10px); }
  100% { opacity: 1; transform: translate(-50%, 0); }
}
</style>
