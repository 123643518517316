import { createRouter, createWebHistory } from 'vue-router'
import MainDash from '../components/MainDash.vue'

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: MainDash
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutPage.vue')
  },
  {
    path: '/learn',
    name: 'learn',
    component: () => import('../views/LearnPage.vue')
  },
  {
    path: '/discover/:tokenCA',
    name: 'discover',
    component: () => import('../views/DiscoverTokenPage.vue'),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router