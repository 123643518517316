<template>
  <div id="app" :class="{ 'is-mobile': isMobile }" @mousemove="onDrag" @mouseup="stopDrag">
    <!-- Mobile responsive nav -->
    <nav class="responsive-nav" v-if="isMobile">
      <button class="menu-toggle" @click="toggleMenu" :class="{ 'is-open': menuOpen }">
        <span></span>
      </button>
      <ul class="nav__menu" :class="{ 'is-open': menuOpen }">
        <li>
          <router-link to="/" class="nav-link">
            <i class="fas fa-home"></i>
            <span>Home</span>
          </router-link>
        </li>
        <li>
          <router-link to="/about" class="nav-link">
            <i class="fas fa-info-circle"></i>
            <span>About</span>
          </router-link>
        </li>
        <li>
          <router-link to="/learn" class="nav-link">
            <i class="fas fa-book"></i>
            <span>Learn</span>
          </router-link>
        </li>
      </ul>
    </nav>

    <!-- Main Meme Metrics content -->
    <div class="meme-metrics">
      <header class="dashboard-header">
        <div class="header-background"></div>
        <div class="header-content">
          <div class="header-text">
            <h1>Meme Metrics</h1>
            <p class="subtitle">Your Gateway to Smart Solana Token Analytics</p>
          </div>
          <!-- Desktop nav -->
          <nav class="nav-links" v-if="!isMobile">
            <router-link :to="{ name: 'dashboard' }" class="nav-link">
              <i class="fas fa-home"></i>
              <span>Home</span>
            </router-link>
            <router-link :to="{ name: 'about' }" class="nav-link">
              <i class="fas fa-info-circle"></i>
              <span>About</span>
            </router-link>
            <router-link :to="{ name: 'learn' }" class="nav-link">
              <i class="fas fa-book"></i>
              <span>Learn</span>
            </router-link>
          </nav>
        </div>
      </header>

      <main class="dashboard-content">
        <router-view></router-view>
      </main>

      <footer class="dashboard-footer">
        <div class="footer-content">
          <p class="disclaimer">
            Meme Metrics is for informational purposes only.
          Not financial advice. Trading meme coins involves substantial risk.
        </p>
        <div class="footer-buttons">
          <button @click="showDisclaimers = true" class="disclaimer-btn">
            <i class="fas fa-shield-alt"></i> Legal Disclaimers
          </button>
          <a href="https://x.com/MemeMetricsApp" 
             target="_blank" 
             rel="noopener noreferrer" 
             class="social-btn">
            <img src="./assets/twitter.png" alt="X Logo" class="x-logo">
          </a>
        </div>
        <p class="copyright">
          © 2024 Meme Metrics. All rights reserved.
        </p>
      </div>

      <!-- Add Modal -->
      <div v-if="showDisclaimers" 
           class="modal-overlay" 
           @click="showDisclaimers = false"
           @mousemove="onDrag"
           @mouseup="stopDrag">
        <div class="modal-content" 
             @click.stop
             :style="{
               transform: `translate(${modalPosition.x}px, ${modalPosition.y}px)`
             }">
          <div class="modal-header" 
               @mousedown="startDrag"
               @click.stop>
            <h2>Legal Disclaimers & Terms of Use</h2>
            <button class="close-btn" @click="showDisclaimers = false">&times;</button>
          </div>
          <div class="disclaimers-content">
            <h3>Risk Warning</h3>
            <ul class="disclaimers-list">
              <li>
                <strong>Investment Risk:</strong>
                Cryptocurrency investments are inherently risky and volatile. The value of cryptocurrencies can fluctuate significantly, and there is a risk of losing all or part of your investment.
              </li>
              <li>
                <strong>Data Sources:</strong>
                The information provided on this platform is based on publicly available data, third-party APIs, and blockchain analysis. While we strive for accuracy, we cannot guarantee that the data is error-free, complete, or up-to-date.
              </li>
              <li>
                <strong>User Responsibility:</strong>
                Users assume full responsibility for any financial decisions made using this platform. Past performance is not indicative of future results, and no guarantees are provided regarding the performance or value of any cryptocurrency or financial asset.
              </li>
            </ul>
            
            <h3>Not Financial Advice</h3>
            <ul class="disclaimers-list">
              <li>
                <strong>Informational Purpose:</strong>
                This platform is intended for informational and educational purposes only. It does not provide financial, investment, trading, or other forms of advice.
              </li>
              <li>
                <strong>No Recommendations:</strong>
                The information presented, including metrics, charts, and social insights, is not a recommendation to buy, sell, or hold any cryptocurrency or other financial instrument.
              </li>
              <li>
                <strong>Professional Guidance:</strong>
                Always consult a qualified financial advisor and perform your due diligence before making any investment decisions. This platform should not be used as a substitute for professional financial advice or independent research.
              </li>
            </ul>
            
            <h3>Terms of Use</h3>
            <ul class="terms-list">
              <li>
                <strong>Third-Party Data:</strong>
                This platform aggregates information from third-party sources, including blockchain networks, social media, and external APIs. The platform is not responsible for errors, omissions, or discrepancies in data provided by these external sources.
              </li>
              <li>
                <strong>No Warranties or Guarantees:</strong>
                The developers, operators, and affiliates of this platform do not warrant the accuracy, completeness, or reliability of the information provided. Users acknowledge that all data is presented "as is" and agree to use the platform at their own risk.
              </li>
              <li>
                <strong>Liability:</strong>
                The platform and its developers shall not be held liable for any losses, damages, or adverse outcomes arising from the use of this platform, including but not limited to financial losses or reliance on incomplete or inaccurate data.
              </li>
              <li>
                <strong>Compliance:</strong>
                Users are responsible for ensuring that their activities comply with all applicable laws, regulations, and tax obligations in their respective jurisdictions.
              </li>
            </ul>
            <p class="terms-footer">
              By using this platform, you acknowledge and accept these terms, and you agree to use the platform solely at your own discretion and risk.
            </p>
          </div>
        </div>
      </div>
    </footer>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'

export default {
  name: 'App',
  data() {
    return {
      dragStart: { x: 0, y: 0 }
    }
  },
  setup() {
    // Existing refs for modal
    const showDisclaimers = ref(false)
    const isDragging = ref(false)
    const modalPosition = ref({ x: 0, y: 0 })
    const dragOffset = ref({ x: 0, y: 0 })

    // Mobile refs
    const isMobile = ref(false)
    const menuOpen = ref(false)

    // Drag functionality
    const startDrag = (e) => {
      isDragging.value = true
      dragOffset.value = {
        x: e.clientX - modalPosition.value.x,
        y: e.clientY - modalPosition.value.y
      }
    }

    const onDrag = (e) => {
      if (isDragging.value) {
        modalPosition.value = {
          x: e.clientX - dragOffset.value.x,
          y: e.clientY - dragOffset.value.y
        }
      }
    }

    const stopDrag = () => {
      isDragging.value = false
    }

    // Mobile methods
    const checkDevice = () => {
      isMobile.value = window.innerWidth <= 576
    }

    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value
    }

    // Lifecycle hooks
    onMounted(() => {
      checkDevice()
      window.addEventListener('resize', checkDevice)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', checkDevice)
    })

    return {
      // Modal state
      showDisclaimers,
      isDragging,
      modalPosition,
      dragOffset,
      startDrag,
      onDrag,
      stopDrag,

      // Mobile state
      isMobile,
      menuOpen,
      toggleMenu
    }
  }
}
</script>

<style>
:root {
  --primary-purple: #7928CA;
  --primary-blue: #2D3BF0;
  --neon-purple: #b624ff;
  --dark-purple: #1a0b2e;
  --dark-blue: #0d1b3c;
  --background: #080510;
  --text-glow: #e4b5ff;
  --accent: #00fff2;
  --primary-green: #14F195;
  --primary-purple: #9945FF;
  --dark-purple: #1a0b2e;
  --dark-blue: #0d1b3c;
  --background: #080510;
}

.meme-metrics {
  min-height: 100vh;
  background: linear-gradient(
    135deg,
    var(--dark-purple) 0%,
    var(--background) 25%,
    var(--dark-blue) 50%,
    var(--background) 75%,
    var(--dark-purple) 100%
  );
  background-attachment: fixed;
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  color: white; /* Ensure text is visible on dark background */
}

@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


.meme-metrics::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(90deg, var(--neon-purple), var(--accent));
  opacity: 0.7;
}

body {
  margin: 0;
  padding: 0;
  background: var(--background);
}

.dashboard-header {
  background: linear-gradient(90deg, 
    rgba(41, 15, 50, 0.95) 0%,
    rgba(13, 27, 60, 0.95) 100%);
  padding: 2.5rem 3rem;
  display: flex;
  justify-content: center;
  box-shadow: 0 2px 20px rgba(182, 36, 255, 0.15);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(230, 181, 255, 0.1);
  position: relative;
  overflow: hidden;
  height: 120px;
  margin-bottom: 2rem;
}

.header-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/mmheader.webp');
  background-size: cover;
  background-position: center;
  opacity: 0.4; /* Adjust opacity as needed */
  z-index: 1;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  max-width: 1200px;
  width: 100%;
  position: relative;
  z-index: 2;
}

.logo {
  width: 80px;
  height: 80px;
  filter: drop-shadow(0 0 15px rgba(182, 36, 255, 0.5));
  animation: pulse 4s ease-in-out infinite;
}

.header-text {
  text-align: center;
}

.header-text h1 {
  font-size: 6rem; /* Increased from default */
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #14F195, #9945FF);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 
    0px 2px 4px rgba(0, 0, 0, 0.1);
}

.subtitle {
  font-size: 1.5rem;
  margin: 0.4rem 0 0;
  color: #FFFFFF; /* Brighter white */
  letter-spacing: 1px; /* Increased spacing */
  font-weight: 500; /* Semi-bold */
  text-shadow: 
    0 2px 8px rgba(0, 0, 0, 0.8),
    0 4px 12px rgba(0, 0, 0, 0.6),
    0 6px 16px rgba(0, 0, 0, 0.4),
    0 8px 20px rgba(0, 0, 0, 0.3);
}

@media (max-width: 576px) {
  .header-text h1 {
    font-size: 2.5rem; /* Mobile size */
  }
  
  .subtitle {
    font-size: 1.1rem;
    letter-spacing: 0.75px;
  }
}

h1 {
  font-size: 2.2rem;
  font-weight: 700;
  margin: 0;
  background: linear-gradient(45deg, #14F195, #9945FF);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.subtitle {
  font-size: 1.1rem;
  margin: 0.4rem 0 0;
  color: #E4E4E4;
  letter-spacing: 0.5px;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
  padding: 0.5rem;
}

.nav-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  text-decoration: none;
  font-weight: 500;
  padding: 0.7rem 1.2rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.nav-link:hover {
  background: rgba(20, 241, 149, 0.2);
  transform: translateY(-2px);
  border-color: rgba(20, 241, 149, 0.3);
  box-shadow: 0 4px 15px rgba(20, 241, 149, 0.2);
}

.nav-link.router-link-active {
  background: linear-gradient(45deg, rgba(20, 241, 149, 0.2), rgba(153, 69, 255, 0.2));
  border-color: rgba(20, 241, 149, 0.5);
}

.nav-link i {
  font-size: 1.1rem;
  color: #14F195;
}

.nav-link span {
  font-size: 0.95rem;
  letter-spacing: 0.5px;
}

.discover-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  border-radius: 8px;
  background: linear-gradient(45deg, #14F195, #9945FF);
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
}

.dashboard-content {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(26, 11, 46, 0.3);
  border-radius: 8px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(230, 181, 255, 0.1);
  text-align: center;
}

.dashboard-footer {
  background: linear-gradient(90deg, 
    rgba(41, 15, 50, 0.95) 0%,
    rgba(13, 27, 60, 0.95) 100%);
  padding: 1.5rem;
  margin-top: auto;
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(230, 181, 255, 0.1);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.disclaimer {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.disclaimer-btn {
  background: transparent;
  border: 1px solid var(--accent);
  color: var(--accent);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  margin: 1rem 0;
  transition: all 0.3s ease;
}

.disclaimer-btn:hover {
  background: rgba(0, 255, 242, 0.1);
}

.footer-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 1rem 0;
  align-items: center;
}

.social-btn {
  background: transparent;
  border: 1px solid var(--accent);
  color: var(--accent);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px; /* Match height with disclaimer button */
}

.x-logo {
  height: 20px;
  width: auto;
  filter: brightness(0) saturate(100%) invert(80%) sepia(54%) 
          saturate(757%) hue-rotate(127deg) brightness(103%) contrast(103%);
  transition: filter 0.3s ease;
}

.social-btn:hover {
  background: rgba(0, 255, 242, 0.1);
  transform: translateY(-1px);
}

.social-btn:hover .x-logo {
  filter: brightness(0) saturate(100%) invert(100%);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: linear-gradient(135deg, var(--dark-purple) 0%, var(--dark-blue) 100%);
  border: 1px solid var(--neon-purple);
  border-radius: 8px;
  padding: 2rem;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  position: fixed;
  user-select: none;
  transition: none;
  transform: translateY(-10%); /* Move up by 10% of height */
  animation: modalSlideIn 0.3s ease-out;
}

.modal-header {
  padding: 1rem;
  cursor: move;
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid var(--neon-purple);
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  margin: 0;
  font-size: 1.2rem;
}

.close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: transparent;
  border: none;
  color: var(--accent);
  font-size: 1.5rem;
  cursor: pointer;
}

.disclaimers-content h2 {
  color: var(--accent);
  margin-bottom: 1.5rem;
}

.disclaimers-content h3 {
  color: var(--text-glow);
  margin: 1rem 0;
}

.disclaimers-content p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 1rem;
  line-height: 1.6;
}

.disclaimers-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.disclaimers-list li {
  margin-bottom: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

.disclaimers-list strong {
  color: var(--accent);
  display: block;
  margin-bottom: 0.5rem;
}

.terms-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.terms-list li {
  margin-bottom: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
}

.terms-list strong {
  color: var(--accent);
  display: block;
  margin-bottom: 0.5rem;
}

.terms-footer {
  margin-top: 1.5rem;
  font-style: italic;
  color: rgba(255, 255, 255, 0.7);
}

@keyframes modalSlideIn {
  from {
    opacity: 0;
    transform: translateY(0%);
  }
  to {
    opacity: 1;
    transform: translateY(-10%);
  }
}

.copyright {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
}

@keyframes pulse {
  0%, 100% { filter: drop-shadow(0 0 8px rgba(182, 36, 255, 0.5)); }
  50% { filter: drop-shadow(0 0 15px rgba(182, 36, 255, 0.8)); }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 0.9;
    transform: translateY(0);
  }
}

.responsive-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background: rgba(26, 11, 46, 0.95);  /* Updated for dark theme */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.menu-toggle {
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 44px;
  height: 44px;
  padding: 8px;
  position: relative;
}

.menu-toggle span {
  display: block;
  width: 28px;
  height: 2px;
  background: #14F195;
  border-radius: 2px;
  position: relative;
  transition: all 0.3s ease;
}

.menu-toggle span::before,
.menu-toggle span::after {
  content: '';
  position: absolute;
  width: 28px;
  height: 2px;
  background: #14F195;
  border-radius: 2px;
  transition: all 0.3s ease;
}

.menu-toggle span::before {
  transform: translateY(-8px);
}

.menu-toggle span::after {
  transform: translateY(8px);
}

/* Animation for active state */
.menu-toggle.is-open span {
  background: transparent;
}

.menu-toggle.is-open span::before {
  transform: rotate(45deg) translateY(0);
}

.menu-toggle.is-open span::after {
  transform: rotate(-45deg) translateY(0);
}

.is-mobile .menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}

.nav__menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 576px) {
  .nav__menu {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    background: rgba(26, 11, 46, 0.95);
    flex-direction: column;
    transform: translateX(100%);
    transition: transform 0.3s ease;
    height: calc(100vh - 60px);
    padding: 1.5rem;
    gap: 1rem;
    backdrop-filter: blur(10px);
  }

  .nav__menu.is-open {
    transform: translateX(0);
  }

  .nav__menu li {
    opacity: 0;
    transform: translateX(20px);
    transition: all 0.3s ease;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .nav__menu.is-open li {
    opacity: 1;
    transform: translateX(0);
  }

  .nav__menu .nav-link {
    padding: 1.2rem 1.5rem;
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  .nav__menu .nav-link i {
    font-size: 1.4rem;
    color: #14F195;
  }

  .nav__menu .nav-link span {
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.9);
  }

  .nav__menu .router-link-active {
    background: linear-gradient(45deg, rgba(20, 241, 149, 0.2), rgba(153, 69, 255, 0.2));
    border: 1px solid rgba(20, 241, 149, 0.3);
  }
}


.nav-link.router-link-active {
  background: linear-gradient(45deg, rgba(20, 241, 149, 0.2), rgba(153, 69, 255, 0.2));
  border-color: rgba(20, 241, 149, 0.5);
}

.nav-link i {
  font-size: 1.1rem;
  color: #14F195;
}

.nav-link span {
  font-size: 0.95rem;
  letter-spacing: 0.5px;
}

.dashboard-content {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(26, 11, 46, 0.3);
  border-radius: 8px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(230, 181, 255, 0.1);
  text-align: center;
}

@media (max-width: 576px) {
  .dashboard-content {
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 4px;
  }

  .nav-link {
    padding: 0.5rem;
    margin: 0.25rem;
  }

  .nav-link i {
    font-size: 1rem;
  }

  .nav-link span {
    font-size: 0.85rem;
  }
}

.dashboard-footer {
  background: linear-gradient(90deg, 
    rgba(41, 15, 50, 0.95) 0%,
    rgba(13, 27, 60, 0.95) 100%);
  padding: 1.5rem;
  margin-top: auto;
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(230, 181, 255, 0.1);
}

@media (max-width: 576px) {
  .dashboard-footer {
    padding: 1rem;
  }
  
  .footer-content {
    padding: 0 0.5rem;
  }
  
  .disclaimer {
    font-size: 0.9rem;
    line-height: 1.4;
  }
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
}

.disclaimer {
  color: rgba(255, 255, 255, 0.7);
}

</style>

<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">

